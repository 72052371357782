import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layout';
import Header from '../components/header/header';
import Banner from '../components/banner/banner';
import './index.scss';
import Slide1 from '../assets/images/slide-1.jpg';
import About from '../components/about/about';
import Contact from '../components/contact/contact';
import Footer from '../components/footer/footer';

const { marketingWebUrl } = process.env;
const ogImageUrl = marketingWebUrl + Slide1;

export const Index = () => {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./${fileName}.${style} file.
   */
  return (
    <Layout>
      <Helmet title="Syccure | Authorization Re-Imagined">
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content={ogImageUrl} />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Syccure | Authorization Re-Imagined"
        />
        <meta property="og:description" content="Authorization Re-Imagined" />
        <meta property="og:url" content={marketingWebUrl} />
        <meta property="og:site_name" content="Syccure" />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Header headerClass={'header haeder-black'} />
      <Banner />
      <About />

      <Contact></Contact>
      <Footer></Footer>
    </Layout>
  );
};

export default Index;

import React from 'react';
import { Link } from 'gatsby';
import Carousel from 'react-bootstrap/Carousel';
import Slide1 from '../../assets/images/home-banner.jpg';
import logoHeader from '../../assets/images/logo-header.png';

import './banner.scss';

/* eslint-disable-next-line */
export interface BannerProps {}

export function Banner(props: BannerProps) {
  return (
    <div className="bannerHolder">
      <Carousel pause={false} indicators={false} controls={false}>
        <Carousel.Item>
          <img src={Slide1} alt="Slide" />
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <div className="headerLogo">
                  <img src={logoHeader} alt="logo header" />
                </div>
                <h2 className="text-uppercase">
                  <span>trust made easy</span>{' '}
                </h2>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Banner;
